'use client'

import UpsellingModal from 'components/modals/UpsellingModal'
import { JSX, ReactNode, useCallback, useMemo, useState } from 'react'

import { DEFAULT_DATA, Provider as ContextProvider } from './Context'

function Provider({
  children,
}: { children: ReactNode; }): JSX.Element {
  const [showUpsellingModal, setShowUpsellingModal] = useState(false)

  const onClose = useCallback(
    () => setShowUpsellingModal(false),
    []
  )

  const onShow = useCallback(
    () => setShowUpsellingModal(true),
    []
  )

  const contextData = useMemo(() => ({
    ...DEFAULT_DATA,
    show: onShow,
    close: onClose,
  }), [onClose, onShow])

  return (
    <ContextProvider value={contextData}>
      {children}
      {showUpsellingModal && (
        <UpsellingModal onClose={onClose}  />
      )}
    </ContextProvider>
  )
}

export default Provider
