// import Image1SVG from 'assets/images/upselling/UPSELLING-1.svg'
// import Image2SVG from 'assets/images/upselling/UPSELLING-2.svg'
import { Button } from 'atoms'
import Modal from 'atoms/Modal'
import { useTranslation } from 'i18n/client'
// import isaac from 'isaac'
import { FC, useEffect, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import WorldVayapinsMesaSVG from 'svg/generated/upselling/WorldVayapinsMesa'
import Styles from './Style.module.sass'

// const IMAGE_SET = [Image1SVG, Image2SVG]

export interface UpsellingModalProps {
  onClose?: () => void;
}

const UpsellingModal: FC<UpsellingModalProps> = ({ onClose = () => undefined }) => {
  const { t } = useTranslation()
  const [disableClose, setDisableClose] = useState(true)
  const [countdown, setCountdown] = useState(3)

  // // Image
  // const randomImageSource = useMemo(() => {
  //   const randNumber = (Math.floor(isaac.random() * IMAGE_SET.length))
  //   return IMAGE_SET[randNumber] as StaticImageData
  // }, [])

  // // Content text
  // const contentText = useMemo<string>(
  //   () => t('pin.upsellingModal.message'),
  //   [t]
  // )

  //
  // Enable close after 3 sec
  useEffect(() => {
    const timer = setTimeout(() => {
      setDisableClose(false)
    }, 3000)

    return function cleanUp() {
      if (timer) clearTimeout(timer)
    }
  }, [])

  //
  // Start handler
  useEffect(() => {
    if (!countdown) return

    const timer = setTimeout(() => {
      setCountdown(countdown - 1)
    }, 1000)

    return function cleanUp() {
      if (timer) clearTimeout(timer)
    }
  }, [setCountdown, countdown])

  //
  // Render
  return (
    <Modal
      showHeader
      disableClose={disableClose}
      isOpen={true}
      onClose={onClose}
      modalHeaderCloseLabel={countdown == 0 ? <GrClose /> : countdown}
    >
      <div className={Styles.container}>
        <h2 className={Styles.headline}>
          {t('pin.upsellingModal.headline', 'With VayaPin, YOU ARE YOUR ADDRESS!')}
        </h2>
        <p className={Styles.subHeadline}>
          {t('pin.upsellingModal.paragraph1',
            'A precise address that help ensure that all your deliveries and people can find you')}
        </p>
        <p className={Styles.boldParagraph}>
          {t('pin.upsellingModal.paragraph2Bold',
            'THE FIRST TIME,\nEVERY TIME')}
        </p>
        {/* <Image
          src={WorldVayaPinsMesaSVG}
          alt="VayaPin"
          width={500}
        /> */}
        <WorldVayapinsMesaSVG width={500} />
        <p className={Styles.footerContent}>
          {t('pin.upsellingModal.paragraph3',
            'ALL the relavant information about a location, person or business in ONE place')}
        </p>
        <div style={{ textAlign: 'center' }}>
          <Button
            theme="primary"
            href="https://www.vayapin.com/"
            type="link"
            target='_blank'
            onClick={onClose}
          >
            {t('pin.upsellingModal.button', 'Learn More')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default UpsellingModal
