import noop from 'lodash/noop'
import { createContext } from 'react'

import type { ContextData } from './Types'

export const DEFAULT_DATA: ContextData = {
  show: noop,
  hide: noop,
}

// Context instance
const Context = createContext(DEFAULT_DATA)
Context.displayName = 'UpsellingModalContext'

// export provider/consumer
export const Provider = Context.Provider
export const Consumer = Context.Consumer

// Export the context
export default Context
